import { createApp } from "vue";
import Structure from "./Structure.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "./theme/styles/defaults.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faPhone,
  faEnvelope,
  faHome,
  faTruckFast,
  faImages,
  faBullhorn,
  faCircleInfo,
  faCaretRight,
  faCompass,
  faHandHoldingHeart,
  faHammer,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faBars,
  faEnvelope,
  faPhone,
  faHome,
  faTruckFast,
  faImages,
  faBullhorn,
  faCircleInfo,
  faCaretRight,
  faCompass,
  faHandHoldingHeart,
  faHammer,
  faThumbsUp
);

createApp(Structure)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .mount("#wrapper");
