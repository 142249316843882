
import { defineComponent } from "vue";

export default defineComponent({
  name: "Menu",
  data: function () {
    return {
      isShow: true,
      mobileNav: true,
      scrollNav: false,
      windowWidth: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.screenSize);
    this.screenSize();
  },
  mounted() {
    window.addEventListener("scroll", this.shortNav);
  },
  methods: {
    toggleNav() {
      this.isShow = !this.isShow;
    },
    screenSize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 800) {
        this.mobileNav = true;
        this.isShow = true;
        return;
      }
      this.mobileNav = false;
      this.isShow = false;
    },
    shortNav() {
      const scrollPos = window.scrollY;
      if (scrollPos > 50) {
        this.scrollNav = true;
        return;
      }
      this.scrollNav = false;
    },
  },
  props: {
    msg: String,
  },
});
