<template>
  <Header />
  <div id="body-wrap">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import { defineComponent } from "vue";
import Header from "@/components/global/header.vue";
import Footer from "@/components/global/Footer.vue";

export default defineComponent({
  components: {
    Header,
    Footer,
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Cabin+Sketch:wght@700&family=Fjalla+One&family=Hammersmith+One&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Oswald:wght@200;300;400;500;600;700&family=Pathway+Gothic+One&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

@import url("https://res.cloudinary.com/dr6lvwubh/raw/upload/v1581441981/Anicons/anicons.css");

/* Hammersmith - Titles
   Open Sans - Text
   Cabin Sketch - Specials w/ Open Sans
   */

.font-list {
  div {
    margin: 30px 0;
  }
  h1,
  h2 {
    margin: 2px;
  }

  h1 {
    font-size: 1rem;
    margin: 10px 0 0 0;
  }
  h2 {
    font-size: 1.5rem;
  }
  p {
    margin: 0;
    font-size: 2rem;
    text-decoration: underline;
  }
  .caps {
    text-transform: uppercase;
  }
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  min-width: 300px;

  @media (min-width: 768px) {
    font-size: 22px;
  }
}

#body-wrap {
  padding-top: var(--menu-height-mobile);
  @media (min-width: 800px) {
    padding-top: var(--menu-height);
  }
}

.subtitle {
  font-size: 1rem;

  @media (min-width: 600px) {
    font-size: 1.9rem;
  }
}

h3 {
  font-size: 2rem;
  font-family: "Pathway Gothic One", sans-serif;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1rem;
}

.caps {
  text-transform: uppercase;
}

.adTitle {
  font-size: 1.6rem;
  margin: 2px 0 0 0;
  font-family: "Cabin Sketch", cursive;
  color: var(--white);

  @media (min-width: 500px) {
    font-size: 2.4rem;
  }
  @media (min-width: 1000px) {
    font-size: 3rem;
  }
}

.adSub {
  font-size: 1rem;
  margin: 2px 0;
  font-family: "Open Sans", sans-serif;
  color: #efefef;
  @media (min-width: 500px) {
    font-size: 1.4rem;
  }
  @media (min-width: 1000px) {
    font-size: 1.8rem;
  }
}
</style>
