
import { Options, Vue } from "vue-class-component";
import Services from "@/components/Services_comp.vue";
import Callout from "@/components/Callout.vue";

@Options({
  components: {
    Services,
    Callout,
  },
})
export default class Home extends Vue {}
